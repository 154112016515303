import 'reflect-metadata';
import { autoinject, singleton, Container, observable, PLATFORM } from 'aurelia-framework';
import { NavigationInstruction, Next, PipelineStep, Redirect, RouterConfiguration } from 'aurelia-router';
import { Router, RouteConfig } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { I18N } from 'aurelia-i18n';
import AuthService from './authService';
import { Application, AppSettings } from 'utils/app-settings';
import 'bootstrap-select';
import 'bootstrap-select/dist/css/bootstrap-select.min.css';

class AuthorizeStep implements PipelineStep {
  public run(navigationInstruction: NavigationInstruction, next: Next): Promise<any> {
    if (navigationInstruction.getAllInstructions().some(i => i.config.settings.roles && i.config.settings.roles.indexOf('admin') !== -1)) {

      let authService = <AuthService>Container.instance.get(AuthService)

      console.log("Checking auth:", authService.getUsername(), authService.inSuperGroup());

      if (!authService.isAuthenticated()) {
        return next.cancel(new Redirect('login'));
      }
    }

    return next();
  }
}

let titulares: string[] = [
  "titular1",
  "titular2",
  "titular3"
];

class Language {
  name: string = "";
  code: string = "";

  constructor(name: string, code: string) {
    this.name = name;
    this.code = code;
  }
}

@singleton()
@autoinject()
export class App {
  public router: Router;

  languages: Language[] = [];
  currentLng: Language;

  // flashContainer: EyFlashMessage;
  isSetup: boolean = false;

  @observable slogan: string = "";

  configureRouter(config: RouterConfiguration, router: Router) {

    console.log("APP configure router");

    config.title = 'Preventium Global System';
    config.addAuthorizeStep(AuthorizeStep);
    // config.mapUnknownRoutes('404');
    config.map([
      { route: ['', 'empresas'], name: 'index', redirect: 'empresas' },
      // Empresas
      { route: 'empresas', name: 'empresas', moduleId: PLATFORM.moduleName('./pages/empresas/index'), nav: false, title: 'Empresas' },
      { route: 'validacion', name: 'validacion', moduleId: PLATFORM.moduleName('pages/trabValidacion/validacion') },
      { route: '404', name: '404', moduleId: PLATFORM.moduleName('pages/404/404') },
    ]);
    this.router = router;

    //default route, to avoid the "route not found error"
    config.mapUnknownRoutes(instruction => {
      return { redirect: 'empresas' };
    });
  }

  constructor(public app: Application,
    private authService: AuthService,
    private eventAggregator: EventAggregator,
    public i18n: I18N) {
    console.log("APP constructor");

    // this.languages.push(new Language("English", "en"));
    this.languages.push(new Language("Español", "es"));
    this.languages.push(new Language("Catalan", "cat"));
    this.languages.push(new Language("English", "en"));

    this.currentLng = this.findLanguage(app.persist('locale'));
    if (!this.currentLng) {
      var defaultLang = navigator.language;
      for (let lng of this.languages) {
        if (defaultLang.includes(lng.code)) {
          this.currentLng = lng;
          break;
        }
      }
    }

    if (!this.currentLng) {
      this.currentLng = this.findLanguage('es');
    }

    // eventAggregator.subscribe('i18n:locale:changed', payload => this.i18n.updateTranslations(this.element));
    // eventAggregator.subscribe('router:navigation:error', i => this.app.navigateTo('404'));
    this.eventAggregator.subscribe('router:navigation:success', this.navigationSuccess.bind(this));

    this.i18n.setLocale(this.currentLng.code);

  }

  findLanguage(code: string) {
    for (let lng of this.languages) {
      if (code == lng.code) {
        return lng;
      }
    }
    return null;
  }

  attached() {

    console.log("APP NAVIGATION", this.router.navigation);

    // Load initial translations
    // this.i18n.updateTranslations(this.element)

    // Get Initital information
    // this.api.StartConnection();
  }

  setLocale(lng: Language) {
    this.currentLng = lng;
    this.updateLocale();
  }

  updateLocale() {
    this.app.isBusy = true;
    this.i18n.setLocale(this.currentLng.code).then(() => {
      this.app.persist('locale', this.currentLng.code);
      setTimeout(() => this.app.isBusy = false, 50);
    });
  }

  logout() {
    console.log("LOGOUT");
    this.authService.logout();
  }

  navigate(navigationInstruction) {
    console.log("app::navigate");
    navigationInstruction.router.navigateToRoute(navigationInstruction.config.name, navigationInstruction.params);
  }

  navigationSuccess() {
    console.log("app::navigationSuccess");
    if (this.router) {
      this.isSetup = this.router.currentInstruction.config.settings.isSetup;
      this.selectSlogan();
    }
  }

  selectSlogan() {
    let size = titulares.length;
    let idx = Math.floor(Math.random() * size);
    this.slogan = this.i18n.tr(titulares[idx]);
  }
}
